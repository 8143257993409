import React, { useEffect } from "react";
import { VerticalBarChartGrouped } from '@eds/vanilla';

export default function AiPerformance({getAiPerformanceData}) {
    const Data = {
        "common": getAiPerformanceData.map(i => i.includes('common') && i[1]).flat().filter(Boolean),
        "series": getAiPerformanceData.map(i => i.includes('series') && i[1]).flat().filter(Boolean),
    }
    
    useEffect(() => {
        const chart = new VerticalBarChartGrouped({
            element: document.getElementById('simple-grouped-example'),
            data: Data,
            y: { unit: 'Test Data' }
        });
        chart.init();
        return () => {
            chart.destroy();
        };
    }, [])

    return (
        <div id="simple-grouped-example"></div>
    );
}
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import Loading from "../../components/Loading/Loading";

import _ from "lodash";
import AiPerformance from "./AiPerformance";
import SafetyMetricsCard from "./SafetyMetricsCard";
import { getSafetyMetrics, getAiPerformance } from '../../api/SafetyMetrics.api';
import CustomDatePickerForm from "../../components/CustomDatePickerForm/CustomDatePickerForm";

export default function SafetyMetrics() {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [currentDay, setCurrentDay] = useState(moment().local().format("YYYY-MM-DD"));
    const [filterData, setfiltersData] = useState({});
    const [isAutoCall, setIsAutoCall] = useState(false);
    const [filtersLoading, setFiltersLoading] = useState(false);
    const { headersList } = useSelector((state) => state.envVariables);
    const [getSafetyMetricsData, setSafetyMetricsData] = useState();
    const [getAiPerformanceData, setAiPerformanceData] = useState();
    const [getErrMsg, setErrMsg] = useState({
        safetyMetrics: "",
        AiPerformance: ""
    });
    const [getLoading, setLoading] = useState({
        safetyMetrics: false,
        AiPerformance: false
    });

    let datepickerData = {
        startDate,
        setStartDate,
        endDate,
        setEndDate,
    };

    let param = {
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
    };

    const getDisableApplyToggle = useMemo(() => {
        return Object.values(getLoading).includes(true) || filtersLoading;
    }, [filtersLoading]);

    useEffect(() => {
        
        // const intervalId = setInterval(() => {
            const newDay = moment().local().format("YYYY-MM-DD");
            
            // if (currentDay !== newDay) {
                setCurrentDay(newDay);
                
                setStartDate(
                    new Date(
                        moment(currentDay, "YYYY-MM-DD").format("YYYY-MM-DD 00:00:00")
                    )
                );
                setEndDate(
                    new Date(moment(newDay, "YYYY-MM-DD").format("YYYY-MM-DD 23:59:59"))
                );
                setIsAutoCall(true);
            // }
        // }, 60000);
        // return () => clearInterval(intervalId);

    }, []);


    useEffect(() => {
        debugger
        const fetchData = async () => {
            await getSafetyMetrics(setLoading, setErrMsg, setSafetyMetricsData, headersList);
            await getAiPerformance(setLoading, setErrMsg, setAiPerformanceData, headersList);
            setIsAutoCall(false);
        }
        if (isAutoCall) {
            fetchData();
        }
    }, [isAutoCall]);

    return (
        <div className="row">
            <div className="tile filterDiv">
                <CustomDatePickerForm
                    formName="safetyMetrics"
                    datepickerData={datepickerData}
                    filterData={filterData}
                    callApply={() => {
                        setIsAutoCall(true);
                    }}
                    disableButtton={getDisableApplyToggle}
                // filtersAccess={filtersAccess}
                />
            </div>
            {filtersLoading ?
                (<Loading />)
                :
                (<div className="row SafetyMetrics">
                    <div className="tile sm-12 md-12 lg-12 xl-12">
                        <div className="header">
                            <div className="left">
                                <div className="title">Safety Metrics</div>
                            </div>
                        </div>
                        <div className="customRow">
                            {getLoading.safetyMetrics || _.isEmpty(getSafetyMetricsData) ? (
                                <Loading />
                            ) : (
                                <>
                                    {getErrMsg.safetyMetrics !== "Success" ? (
                                        <AlertEDS
                                            errMsg={getErrMsg.safetyMetrics}
                                            status_code={201}
                                            close={false}
                                        />
                                    ) : (
                                        <SafetyMetricsCard
                                            getSafetyMetricsData={
                                                !_.isEmpty(getSafetyMetricsData) && getSafetyMetricsData
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </div>

                    </div>

                    <div className="tile sm-12 md-12 lg-12 xl-12">
                        <div className="header">
                            <div className="left">
                                <div className="title">AI performance</div>
                            </div>
                        </div>
                        <div className="content">
                            {getLoading.AiPerformance || _.isEmpty(getAiPerformanceData) ? (
                                <Loading />
                            ) : (
                                <>
                                    {getErrMsg.AiPerformance !== "Success" ? (
                                        <AlertEDS
                                            errMsg={getErrMsg.AiPerformance}
                                            status_code={201}
                                            close={false}
                                        />
                                    ) : (
                                        <AiPerformance
                                            getAiPerformanceData={
                                                !_.isEmpty(getAiPerformanceData) && getAiPerformanceData
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </div>

                    </div>

                </div>)
            }
        </div>
    );
}

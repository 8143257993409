import React from "react";
import Card from "../../components/Card/Card";
import './safetyMetrics.scss';
export default function SafetyMetricsCard({getSafetyMetricsData}) {


    return (
        <>
            {getSafetyMetricsData?.map((i, k) => {
                return (
                    <Card
                        key={k}
                        title={i[0]}
                        count={i[1]}
                        // loadingVal={loadingVal.groupStats}
                        hoverText={i[0]}
                        details="TestData"
                    // isHoverTextRequire={true}
                    />
                )
            })}</>
    );
}

import { URL } from "../utils/Constant";
import _ from "lodash";

export const getSafetyMetrics = async (setLoading, setErrMsg, setSafetyMetricsData, headersList) => {
    setLoading((val) => ({ ...val, safetyMetrics: true }));
    try {
        const resp = await fetch(URL.ERICSION.GET_SAFETY_METRICS, {
            method: "GET",
            headers: headersList,
        });
        if (resp.ok) {
            const data = await resp.json();
            if (data.status_code === 200) {
                setErrMsg((val) => ({ ...val, safetyMetrics: data.message }));
                setSafetyMetricsData(Object.entries(data.data));
            } else {
                setErrMsg({ safetyMetrics: "No data available in this date range" });
                setSafetyMetricsData();
            }
        } else {
            setSafetyMetricsData();
            setErrMsg((val) => ({
                ...val,
                safetyMetrics: "Internal Server Error. Please try again",
            }));
        }
    } catch {
        setSafetyMetricsData([]);
        setErrMsg((val) => ({ ...val, safetyMetrics: "Internal Server Error. Please try again" }));

    }
    setLoading((val) => ({ ...val, safetyMetrics: false }));
};

export const getAiPerformance = async (setLoading, setErrMsg, setAiPerformanceData, headersList) => {
    setLoading((val) => ({ ...val, AiPerformance: true }));
    try {
        const resp = await fetch(URL.ERICSION.GET_AI_PERFORMANCE, {
            method: "GET",
            headers: headersList,
        });
        if (resp.ok) {
            const data = await resp.json();
            if (data.status_code === 200) {
                setErrMsg((val) => ({ ...val, AiPerformance: data.message }));
                setAiPerformanceData(Object.entries(data.data));
            } else {
                setErrMsg({ AiPerformance: "No data available in this date range" });
                setAiPerformanceData();
            }
        } else {
            setAiPerformanceData();
            setErrMsg((val) => ({
                ...val,
                AiPerformance: "Internal Server Error. Please try again",
            }));
        }
    } catch {
        setAiPerformanceData([]);
        setErrMsg((val) => ({ ...val, AiPerformance: "Internal Server Error. Please try again" }));

    }
    setLoading((val) => ({ ...val, AiPerformance: false }));
};